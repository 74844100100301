*,*::after, *::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "promptRegular", sans-serif;
    font-size: 1rem;
    overflow-x: hidden !important;
    padding:25px;
    min-height: 100vh;
    width:100%;
}

#root, .App{
    width:100%;
    min-height:90vh;
}


/*Je stylise la page de connexion*/

.connexion{
    width:fit-content;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    background-color: rgba(31, 31, 31, 0.526);
    padding:30px;
}


header {
    min-height: 100vh;
    position: relative;
}

.titlePage{
    font-family: "Tan-Pearl", sans-serif;
    text-transform: uppercase;
    font-size: 5rem;
    color: #DBD7C5;
    font-weight: 300;
    position:absolute;
    bottom:80px;
}

.logoContainer{
    width:100%;
    text-align: center;
}

.logo{
    width:150px;
}



/*Je mets le CSS de l'animation du header*/

.firefly {
    width: 5px;
    height: 5px;
    position: absolute;
    background-color: #cabb9e;
    box-shadow: 0px 0px 10px 1px rgba(253, 242, 163, 0.6);
    border-radius: 50%;
    filter: blur(1px);
}



.reservationList, .statsResa{
    margin:40px 30px 40px 30px;
}

.reservationList{
    margin-bottom:100px;
}

.background{
    background-image: url(./images/fond.jpg);
    background-size: cover;
    background-position: left;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
}

.App{
    position: relative;
    z-index:20;
}

/*Je stylise la liste des résas*/

h2{
    color:white;
    margin-bottom:50px;
}

table {
    border-collapse: collapse;
    margin:auto;
}

td {
    border-bottom: solid 1px white;
    border-top: solid 1px white;
    padding: 15px 5px 15px 5px;
    margin:0;
    color:white;
}

th{
    background-color: white;
    border: solid 1px black;
    padding: 5px;
    margin:0;
    color:black;
}

.delete, .update{
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color:white;
    border:none;
    border-radius:50px;
    padding:7px 15px;
    margin:2px;

}

.close-button{
    color:white;
    cursor:pointer;
    text-align:right;
}

.delete{
    color:white;
    background-color:rgb(23, 23, 23);
}

.update{
    color:rgb(23, 23, 23);
    background-color:#DBD7C5;
}

.success-message{
    color:white;
    margin-bottom:20px;
}

/*Je stylise l'apparition du popup*/

.modal{
    position: relative;
}
.backgroundPopup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}


.formTitle{
    color:white;
    margin:0;
}

.modal-content{
    background-color: black;
    padding:20px 50px 20px 50px;
    position: fixed;
    right:50%;
    top:50%;
    transform: translate(50%,-50%);
    z-index: 1000;
}

form{
    
    display:flex;
    flex-direction: column;
    color:white;
}

label{
    margin-right:10px;
}

.submit{
    width:fit-content;
    font-family: "PromptRegular", sans-serif;
    padding:10px 15px;
    background-color:#DBD7C5;
    border:none;
    border-radius: 50px;
    margin:auto;
    cursor: pointer;

}

.deco{
    position:absolute;
    right:0;
}

/*Je stylise la partie des stats*/

.stats{
    display: flex;
    gap:50px;
    padding-bottom:100px;
}

/*je stylise le graphique*/

.statsResa{
    width: 60%;
}

.chartContainer{
    width:100%;
}

#myChart{
    width:100%;
    height:100%;

}

/*Je stylise l'apparition du nombre de tickets vendus*/
.tickets{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.numberTickets{
    font-size: 7rem;
    font-weight: bold;
    color:white;
    margin:0;
}

.legendTickets{
    margin:0;
    color:white;
    font-family: "PromptBold", sans-serif;
    font-size: 1.8rem;
}

/*je stylise le footer*/

footer{
    background-color: #2F6C68;
    padding:20px;
    width:100vw;
    position:absolute;
    bottom:-25px;
    left:-25px;
    color:white;
    text-align: right;
}

.footerContent{
    margin-right:50px;
}




